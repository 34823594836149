import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { CacheService, GetNewestActiveMobileVersion } from 'src/app/services/cache.service';

@Component({
  selector: 'app-mobile-app-version',
  templateUrl: './mobile-app-version.component.html',
  styleUrls: ['./mobile-app-version.component.scss']
})
export class MobileAppVersionComponent implements OnInit {

  public versionNumber: string;
  public versionName: string;
  public publishDate: Date;
  public url: string;

  constructor(private cacheService: CacheService) { }

  ngOnInit() {
    this.cacheService.getData(GetNewestActiveMobileVersion)
      .pipe(first())
      .subscribe(
        data => {
          if (data) {
            this.versionNumber = data.versionNumber;
            this.versionName = data.versionName;
            this.publishDate = data.publishDate;
            this.url = data.url;
          }
        });
  }

}
