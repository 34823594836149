import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { NavMenuItem } from '../models/nav-menu-item';
import { NavMenuItemMain, NavMenuItemMainAdapter } from '../models/nav-menu-item-main';
import { UserProfile } from '../models/user-profile';
import { AuthenticationService } from './authentication.service';
import { ClientService } from './client-service';
import { CacheService, GetAllClientTasks, GetAllClients} from './cache.service';

@Injectable()
export class NavigationService {
    public navigationMenu: Observable<NavMenuItemMain[]>;
    public currentMenuItem: Observable<string>;
    public currentMenuId: Observable<string>;

    private navigationMenuSubject = new BehaviorSubject<NavMenuItemMain[]>([new NavMenuItemMain("0", "", "/")]);
    private currentMenuItemSubject = new BehaviorSubject<string>("");
    private currentMenuIdSubject = new BehaviorSubject<string>("");

    private flatMenuItems: NavMenuItem[] = [
        new NavMenuItem("-", null, "Strona główna", "/"),
        new NavMenuItem("-klienci", null, "Klienci", "/klienci", ["manager_access", "admin_access"]),
        new NavMenuItem("-zadania", null, "Zadania", "/zadania", ["engineer_access"]),
        new NavMenuItem("-raporty", null, "Raporty", "/", ["manager_access"]),
        new NavMenuItem("-raporty-generuj", "-raporty", "Generuj", "/raporty/generuj", ["manager_access"]),
        new NavMenuItem("-admin", null, "Panel Administracyjny", "/admin", ["admin_access"]),
        new NavMenuItem("-admin-uzytkownicy", "-admin", "Zarządzanie użytkownikami", "/admin/uzytkownicy", ["admin_access"])
    ];

    private userRoles: any;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private NavMenuItemMainAdapter: NavMenuItemMainAdapter,
        private clientService: ClientService,
        private cacheService: CacheService
    ) {
        this.navigationMenu = this.navigationMenuSubject.asObservable();
        this.currentMenuItem = this.currentMenuItemSubject.asObservable();
        this.currentMenuId = this.currentMenuIdSubject.asObservable();

        if (!!localStorage.getItem('LoginInfo')) {
            this.generateMenuForUser(JSON.parse(localStorage.getItem('LoginInfo')));
        }

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(
            event => {
                var navigationEndEvent = event as NavigationEnd;
                this.updateCurrentMenuItemByRouterLink(navigationEndEvent.url);
            }
        );

        var user = this.authenticationService.userValue;
        if (user)
        {
            this.generateMenuForUser(user);
            this.addUserClients();
            this.addUserClientTasks();
        }
    }

    public generateMenuForUser(user: UserProfile) {
        this.userRoles = user.userRoles;
        this.refreshNavigationMenu();
    }

    public navigateToItemDirection(menuItemId: string) {
        var selectedMenuItem = this.flatMenuItems.find(item => item.combinedId == menuItemId);
        if (!selectedMenuItem)
            selectedMenuItem = new NavMenuItem("0", "0", "", "/");

        this.router.navigate([selectedMenuItem.routerLink]);
    }

    public navigateToRoute(routerLink: string) {
        var selectedMenuItem = this.flatMenuItems.find(item => item.routerLink == routerLink);
        if (!selectedMenuItem)
            selectedMenuItem = new NavMenuItem("0", "0", "", "/");

        this.router.navigate([routerLink]);
    }

    private refreshNavigationMenu() {
        var menu = this.navigationMenuSubject.value;
        menu = [];
        var mainItems = this.flatMenuItems.filter(item => item.parentId == null && (item.allowedRoles == null || item.allowedRoles.some(role => this.userRoles.includes(role))));
        for (let mainItem of mainItems) {
            var subItems = this.flatMenuItems.filter(item => item.parentId == mainItem.id);
            menu.push(this.NavMenuItemMainAdapter.adapt({ main: mainItem, subItems: subItems }));
        }
        this.navigationMenuSubject.next(menu);
    }

    private addUserClients() {
        this.cacheService.getData(GetAllClients).pipe(first()).subscribe(
            data => {
                let clients = this.clientService.prepareClientsDetailsAfterGetAllClients(data)
                if (clients) {
                    this.flatMenuItems = this.flatMenuItems.filter(item => item.parentId != '-klienci');

                    clients.forEach(client => {
                        this.flatMenuItems.push(new NavMenuItem(`-klienci-${client.shortName}`, '-klienci', client.fullName, '/klienci', ["manager_access", "admin_access"], client.shortName));
                    });

                    this.refreshNavigationMenu();
                }
            }
        );
    }

    private addUserClientTasks() {
        this.cacheService.getData(GetAllClientTasks).pipe(first()).subscribe(
            data => {
                let clientTasks = this.clientService.prepareClientTasks(data);
                if (clientTasks) {
                    this.flatMenuItems = this.flatMenuItems.filter(item => item.parentId != '-zadania');

                    clientTasks.forEach(clientTask => {
                        this.flatMenuItems.push(new NavMenuItem(`-zadania-${clientTask.clientShortName}-${clientTask.shortName}`, '-zadania', clientTask.fullName, `/zadania`, ["manager_access", "admin_access", "engineer_access"], clientTask.clientShortName, clientTask.shortName));
                    });

                    this.refreshNavigationMenu();
                }
            }
        )
    }

    private updateCurrentMenuItemByRouterLink(routerLink: string) {
        if (routerLink == "" || routerLink == "/")
            routerLink = "/";

        var searchedId = routerLink.replace(/\//gi, "-");
        searchedId = searchedId.replace(/%20/gi, " ");
        var selectedMenuItem = this.flatMenuItems.find(item => item.id == searchedId);

        if (selectedMenuItem) {
            this.currentMenuIdSubject.next(selectedMenuItem.combinedId);

            if (selectedMenuItem.parentId != null) {
                var parentMenuItem = this.flatMenuItems.find(item => item.id == selectedMenuItem.parentId);

                this.currentMenuItemSubject.next(`${parentMenuItem.text} > ${selectedMenuItem.text}`);
            }
            else
                this.currentMenuItemSubject.next(selectedMenuItem.text);
        }
    }

    private getMenuItemByRouterLink(routerLink: string) {
        return this.flatMenuItems.find(item => item.routerLink == routerLink);
    }
}