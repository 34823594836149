import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserProfile } from 'src/app/models/user-profile';
import { AccountService } from 'src/app/services/account.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalNotificationService } from 'src/app/services/global-notification.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {
  public newUserModel = new UserProfile("", "", "", "", "", "", "", "", "");
  public loadedProfileData: UserProfile;
  public newUserForm: UntypedFormGroup;
  public returnPage = "/zadania";
  public signatureLinkIsSet = false;
  public signatureName = "";
  public modalSubscription: Subscription;
  public submitInProgress = false;

  constructor(
    public authenticationService: AuthenticationService,
    public accountService: AccountService,
    private notificationService: GlobalNotificationService) { }

  ngOnInit() {
    this.newUserModel = this.authenticationService.userValue;
    this.loadedProfileData = Object.assign({}, this.newUserModel);
    this.signatureLinkIsSet = this.newUserModel.signatureUrl || this.newUserModel.signatureUrl != "" ? true : false;
    this.signatureName = this.newUserModel.signatureName;
  }

  public checkHowManyLicenseNumbersIsInProfile() {
    if (!this.newUserModel.licenseNumbers || this.newUserModel.licenseNumbers === "")
      return true;
    if (this.newUserModel.licenseNumbers.split("\n").length > 3) {
      this.notificationService.showWarningNotification('Możesz dodać maksymalnie 3 numery pozwoleń.');
      return false;
    }
    return true;
  }

  public checkHowManyMembershipNumbersIsInProfile() {
    if (!this.newUserModel.membershipNumbers || this.newUserModel.membershipNumbers === "")
      return true;
    if (this.newUserModel.membershipNumbers.split("\n").length > 1) {
      this.notificationService.showWarningNotification('Możesz dodać maksymalnie 1 numer członkowski POIIB.');
      return false;
    }
    return true;
  }

  public onSignatureRemove() {
    if (this.signatureName === "") {
      this.notificationService.showWarningNotification("Brak podpisu do usunięcia.");
      return;
    }

    this.accountService.removeSignature(this.signatureName).pipe(first()).subscribe(
      data => {
        if (data.success) {
          this.notificationService.showSuccessNotification("Podpis został usunięty.");
          this.refreshUserProfile();
        }
        else {
          this.notificationService.showErrorNotification(data.message);
        }
      },
      error => {

        this.onErrorEdit(error);
      });
  }

  public onSignatureSet = (files) => {
    if (files.length === 0) {
      return;
    }

    if (this.signatureName != "") {
      this.notificationService.showWarningNotification("Najpierw usuń stary podpis");
      return;
    }

    let filesToUpload: File[] = files;
    const formData = new FormData();

    Array.from(filesToUpload).map((file, _) => {
      return formData.append("SignatureFromProfileEditComponent", file, file.name);
    });

    this.notificationService.showInfoNotificationBasedOnMessage('Rozpoczęto przesyłanie podpisu. Proszę czekać na potwierdzenie.');

    this.accountService.uploadSignature(formData).pipe(first()).subscribe(
      data => {
        if (data.success) {
          this.notificationService.showSuccessNotification("Podpis został dodany.");
          this.refreshUserProfile();
        }
        else {
          this.notificationService.showErrorNotification(data.message);
        }
      },
      error => {
        this.onErrorEdit(error);
      });
  }

  public onEditProfile() {
    if (JSON.stringify(this.loadedProfileData) === JSON.stringify(this.newUserModel)) {
      this.notificationService.showInfoNotificationBasedOnMessage("Wprowadź zmiany w profilu przed próbą zapisu!");
      return;
    }

    if (this.checkHowManyLicenseNumbersIsInProfile() && this.checkHowManyMembershipNumbersIsInProfile()) {
      const userModelToSend = {
        userId: 'userId',
        userName: this.newUserModel.userName,
        email: this.newUserModel.email,
        firstName: this.newUserModel.firstName,
        lastName: this.newUserModel.lastName,
        membershipNumbers: this.newUserModel.membershipNumbers,
        licenseNumbers: this.newUserModel.licenseNumbers,
        pictureUrl: this.newUserModel.pictureUrl,
        signature: this.signatureName
      };

      this.submitInProgress = true;
      this.accountService.editProfileDetails(userModelToSend)
        .pipe(first())
        .subscribe(
          data => {
            if (data.success) {
              this.afterEditProfileDetails(data);
            }
            else {
              this.notificationService.showErrorNotification(data.message);
            }    
            this.submitInProgress = false;
          },
          error => {
            this.onErrorEdit(error);
          });
    }
  }

  afterEditProfileDetails(data: any) {
    if (data.success) {
      this.notificationService.showSuccessNotification("Aktualizacja profilu przebiegła pomyślnie.");
      this.refreshUserProfile();
    }
    else {
      this.notificationService.showErrorNotification(data.message);
    }
  }

  refreshUserProfile() {
    this.authenticationService.refreshToken()
      .subscribe(
        () => {
          this.newUserModel = this.authenticationService.userValue;
          this.loadedProfileData = Object.assign({}, this.newUserModel);

          this.signatureName = this.newUserModel.signatureName;
          if (this.newUserModel.signatureUrl && this.newUserModel.signatureUrl != "")
            this.signatureLinkIsSet = true;
          else
            this.signatureLinkIsSet = false;
        }
      );
  }

  public canSetAdditionalFields()
  {
    return !this.authenticationService.isChimneySweep || !this.authenticationService.isClient();
  }

  public onChangePassword() {
    this.resetUserPassword("userId", this.newUserModel.userName);
  }

  private onErrorEdit(error: any) {
    this.submitInProgress = false;
    this.notificationService.showErrorNotificationBasedOnErrorObject(error);
  }

  private resetUserPassword(id: string, username: string) {
    this.submitInProgress = true;
    this.accountService.resetUserPassword(id).pipe(first()).subscribe(
      data => {
        if (data.success) {
          this.notificationService.showSuccessNotification("Wysłano link do zmiany hasła na maila " + username + ".");
          this.refreshUserProfile();
        }
        else {
          this.notificationService.showErrorNotification(data.message);
        }
        this.submitInProgress = false;
      },
      error => {
        this.onErrorEdit(error);
      });
  }
}
