import { Injectable } from '@angular/core';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Adapter } from './interfaces/adapter.interface';
import { ProtocolChimneyElement, ProtocolChimneyElementAdapter } from './protocol-chimney-element';
import { DatePipe } from '@angular/common';
import { ProtocolSignature } from './protocol-signature';

export class FullChimneyProtocol {
    public id: string;
    public protocolNumber: string;
    public controlDate: Date;
    public previousControlDate: Date;
    public nextControlDate: Date;
    public chimneysPhoto: string;
    public chimneysPhotoUrl: string;
    public objectMainPhoto: string;
    public reviewComment: string;
    public protocolChimneyElements: ProtocolChimneyElement[] = [];
    public chimneyTemplate: any;
    public additionalNotes: string;
    public flueLength?: number;
    public clientTypeNumber: number;
    public isHalfyear: boolean;
    public clientTaskId: string;
    public protocolSignatureWithCaseIds: ProtocolSignature;

    constructor(public caseId: string,
        public sapId: string,
        public inventoryId: string,
        public jnDesignation: string) { }
}

@Injectable()
export class FullChimneyProtocolAdapter implements Adapter<FullChimneyProtocol>{
    constructor(private protocolChimneyElementAdapter: ProtocolChimneyElementAdapter) { }

    adapt(item: any): FullChimneyProtocol {
        var protocolChimneyElements = [];

        if (item.protocolChimneyElements) {
            item.protocolChimneyElements.forEach(element => {
                protocolChimneyElements.push(this.protocolChimneyElementAdapter.adapt(element));
            });
        }

        var chimneyProtocol = new FullChimneyProtocol(
            item.caseId,
            item.sapId,
            item.inventoryId,
            item.jnDesignation);

        chimneyProtocol.id = item.id;
        chimneyProtocol.protocolNumber = item.protocolNumber;
        chimneyProtocol.controlDate = item.controlDate;
        chimneyProtocol.previousControlDate = item.previousControlDate;
        chimneyProtocol.nextControlDate = item.nextControlDate;
        chimneyProtocol.reviewComment = item.reviewComment;
        chimneyProtocol.chimneysPhoto = item.chimneysPhoto;
        chimneyProtocol.objectMainPhoto = item.objectMainPhoto;
        chimneyProtocol.protocolChimneyElements = protocolChimneyElements;
        chimneyProtocol.additionalNotes = item.additionalNotes;
        chimneyProtocol.flueLength = item.flueLength;
        chimneyProtocol.clientTypeNumber = item.clientTypeNumber;
        chimneyProtocol.isHalfyear = item.isHalfyear;
        chimneyProtocol.clientTaskId = item.clientTaskId

        return chimneyProtocol;
    }
}

@Injectable()
export class FullChimneyProtocolFormManager implements ModelFormManager<FullChimneyProtocol>{
    private protocolChimneyElementsForm = this.formBuilder.group({
        id: [null],
        elementType: [null, Validators.required],
        elementRating: [null],
        remarksAndObservations: [null]
    });

    private chimneyProtocolForm = this.formBuilder.group({
        id: [null],
        caseId: [null],
        sapId: [null],
        inventoryId: [null],
        jnDesignation: [null],
        protocolNumber: [null],
        controlDate: [this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
        previousControlDate: [this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
        nextControlDate: [this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
        chimneysPhoto: [null],
        flueLength: [0],
        clientTaskId: [null],
        protocolChimneyElements: this.formBuilder.array([this.protocolChimneyElementsForm])
    });

    constructor(private formBuilder: UntypedFormBuilder,
        private datepipe: DatePipe) { }

    buildForm(model: FullChimneyProtocol): UntypedFormGroup {
        if (!model)
            return this.chimneyProtocolForm;

        this.chimneyProtocolForm = this.formBuilder.group({
            id: [model.id],
            caseId: [model.caseId],
            sapId: [model.sapId],
            inventoryId: [model.inventoryId],
            jnDesignation: [model.jnDesignation],
            protocolNumber: [model.protocolNumber],
            controlDate: [model.controlDate ? this.datepipe.transform(model.controlDate, 'yyyy-MM-dd') : this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
            previousControlDate: [model.previousControlDate ? this.datepipe.transform(model.previousControlDate, 'yyyy-MM-dd') : this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
            nextControlDate: [model.nextControlDate ? this.datepipe.transform(model.nextControlDate, 'yyyy-MM-dd') : this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
            chimneysPhoto: [model.chimneysPhoto],
            flueLength: [model.flueLength],
            clientTaskId: [model.clientTaskId],
            protocolChimneyElements: this.formBuilder.array([this.protocolChimneyElementsForm])
        });

        if (!model.protocolChimneyElements || model.protocolChimneyElements.length == 0) {
            this.chimneyProtocolForm = this.addElementsBaseOnTemplate(model.chimneyTemplate);
        } else {
            var elementsArray = (this.chimneyProtocolForm.get('protocolChimneyElements') as UntypedFormArray);
            elementsArray.removeAt(0);
            model.protocolChimneyElements.forEach(element => {
                var elementForm = this.formBuilder.group({
                    id: [element.id],
                    elementType: [element.elementType, Validators.required],
                    elementRating: [element.elementRating],
                    remarksAndObservations: [element.remarksAndObservations]
                });
                elementsArray.push(elementForm);
            });
        }

        return this.chimneyProtocolForm;
    }

    getEmptyForm(): UntypedFormGroup {
        return this.chimneyProtocolForm;
    }

    public addElementsBaseOnTemplate(chimneyProtocolTemplate: any): UntypedFormGroup {
        (this.chimneyProtocolForm.get('protocolChimneyElements') as UntypedFormArray).removeAt(0);

        chimneyProtocolTemplate.categories.forEach(category => {
            category.types.forEach(type => {
                var elementsArray = (this.chimneyProtocolForm.get('protocolChimneyElements') as UntypedFormArray);
                var element = this.formBuilder.group({
                    id: [null],
                    elementType: [type.elementName, Validators.required],
                    elementRating: [''],
                    remarksAndObservations: ['']
                });
                elementsArray.push(element);
            });
        });

        return this.chimneyProtocolForm;
    }

    public removeElement(index: number) {
        var elementsFormArray = (this.chimneyProtocolForm.get('protocolChimneyElements') as UntypedFormArray);
        if (elementsFormArray.length > 1)
            elementsFormArray.removeAt(index);
        return this.chimneyProtocolForm;
    }

    public removeAllElementsButFirst() {
        var elementsFormArray = (this.chimneyProtocolForm.get('protocolChimneyElements') as UntypedFormArray);
        for (let index = elementsFormArray.length - 1; index >= 1; index--) {
            elementsFormArray.removeAt(index);
        }
        return this.chimneyProtocolForm;
    }

    public addNewElementBehindSelected(index: number) {
        var elementsFormArray = (this.chimneyProtocolForm.get('protocolChimneyElements') as UntypedFormArray);
        var selectedElementForm = (elementsFormArray.controls[index] as UntypedFormGroup);
        var newElementForm = this.formBuilder.group({
            id: [null],
            elementType: [selectedElementForm.get('elementType').value, Validators.required],
            elementRating: [''],
            remarksAndObservations: ['']
        });
        elementsFormArray.insert(index + 1, newElementForm);

        elementsFormArray.updateValueAndValidity();

        return this.chimneyProtocolForm;
    }

    public loadElements(elements: ProtocolChimneyElement[]){
        var elementsFormArray = (this.chimneyProtocolForm.get('protocolChimneyElements') as UntypedFormArray);
        while (elementsFormArray.length !== 0) {
            elementsFormArray.removeAt(0)
        }
        elements.forEach(element => {
            var elementForm = this.formBuilder.group({
                id: [element.id],
                elementType: [element.elementType, Validators.required],
                elementRating: [element.elementRating],
                remarksAndObservations: [element.remarksAndObservations]
            });

            (this.chimneyProtocolForm.get('protocolChimneyElements') as UntypedFormArray).push(elementForm);
        });
    }
}